import { Wrapper } from "@googlemaps/react-wrapper";
import { Button, Card, CardContent, CardHeader, CircularProgress, Divider, IconButton, Paper, Skeleton, Table, TextField, Typography } from "@mui/material";
import { TableContainer, TableBody, TableCell, TableHead, TableRow, Chip, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useRef, useEffect } from "react";
import Layout from "../components/shared-layout";
import Map from "../components/map-wrapper";
import MapMarker from "../components/map-marker";
import { googleMapApiKey } from "../constants/settings";
import { observer } from "mobx-react";
import useStores from "../hooks/use-stores";
import VmTable from "../components/shared-table";
import VmModal from "../components/shared-modal";
import VmButton from "../components/shared-button";
import { useTranslation } from "react-i18next";
import PeopleIcon from '@mui/icons-material/People';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import { ITEM_PERFECT_INLINED, TD_FIRST, TD_LAST, TD_NORMAL, THEME_COLOR } from "../constants/style";
import panelImg from "../images/dashboard-panel.png";
import panelImg2 from "../images/dashboard-panel2.png";
import panelImg3 from "../images/dashboard-panel3.png";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import SlideDown from "react-slidedown";

// slide icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HistoryIcon from '@mui/icons-material/History';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import TopicIcon from '@mui/icons-material/Topic';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import PendingIcon from '@mui/icons-material/Pending';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import { DateUtils } from "../utilities/date";

const PAGE_SIZE = 5;
const Dashboard = observer(() => {
  const { userStore, groupStore } = useStores();
  const { t } = useTranslation();
  const [selectedMsg, setSelectedMsg] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedOrg, setSelectedOrg] = useState<number>(0);
  const [selectedVisit, setSelectedVisit] = useState<any>();
  const [clearWindow, setClearWindow] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<any>(new Date(
    new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState<any>(new Date().toISOString().split('T')[0]);
  const [showVisitModal, setShowVisitModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [visitPage, setVisitPage] = useState<number>(1);
  const [orgList, setOrgList] = useState<any>([]);
  const [countHalfHourRequest, setCountHalfHourRequest] = useState<number>(1);
  const [today, setToday] = useState<string>(new Date().toISOString().split('T')[0]);
  const [currentTime, setCurrentTime] = useState<string>(new Date().toLocaleTimeString());
  const [timer, setTimer] = useState<number>(0); // minute

  //june folding const
  const [fold, setFold] = useState<number>(0);

  useEffect(() => {
    userStore.getTodayVisitData();
    userStore.getYearlyVisitData();
    userStore.getMessageList(PAGE_SIZE, 0);
    onRequestTodayVisitListByTime(false);

    // onRequestTodayVisitListByTime(false);
    // onToggleVisitList();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      // if (timer > 0 && timer % 3 == 0) onRequestTodayVisitListByTime(false);
      onRequestTodayVisitListByTime(false);
      userStore.getMessageList(PAGE_SIZE, 0);
      let tempTimer = timer + 1;
      setTimer(tempTimer);
    }, 1000 * 30);
  }, [timer]);

  const handleShowModal = (detail: string, id: number) => {
    userStore.readMessage(id)
      .then(() => {
        userStore.msgList.data.find(msg => msg.id == id).isRead = true;
        userStore.getNewMessageNumber();
      });
    setSelectedMsg(detail);
    setShowModal(true);
  }

  const onToggleVisitList = () => {
    groupStore.getVisitListByDateRange({
      startDate: startDate + "T00:00:00",
      endDate: endDate + "T00:00:00",
      take: 0,
      skip: 0,
    }).then((res) => {
      if (res.data.length > 0) setSelectedOrg(res.data[0].id);
    });
  }

  const onRequestTodayVisitListByTime = (check30minBefore: boolean) => {
    let secondToMin = 0
    if (check30minBefore) secondToMin = 45 * 60 * 1000 * countHalfHourRequest;
    else secondToMin = 15 * 60 * 1000;
    let halfHourBefore = new Date().setTime(new Date().getTime() - (secondToMin));
    let halfHourString = new Date(halfHourBefore).toLocaleTimeString();

    setCurrentTime(halfHourString);
    console.log("123", today + "T" + halfHourString);


    let newTime = halfHourString.split(" ")[0];
    let hour = parseInt(newTime.split(":")[0]);
    let minute = parseInt(newTime.split(":")[1]);
    let seconds = parseInt(newTime.split(":")[2]);
    let hourString = "";
    let minuteString = "";
    let secondsString = "";

    if (halfHourString.split(" ")[1] === "PM" && hour !== 12) {
      hour += 12;
    }

    if (hour < 10) {
      hourString = "0" + hour.toString();
    } else {
      hourString = hour.toString();
    }
    if (minute < 10) {
      minuteString = "0" + minute.toString();
    } else {
      minuteString = minute.toString();
    }

    if (seconds < 10) {
      secondsString = "0" + seconds.toString();
    } else {
      secondsString = seconds.toString();
    }

    console.log("456", today + "T" + hourString + ":" + minuteString + ":" + secondsString);


    userStore.getTodayVisitFullList({ time: today + "T" + hourString + ":" + minuteString + ":" + secondsString })
      .then((res) => {
        let idList = res.map(r => r.finalLocation.id);
        let tempOrgList: any = [];
        [...new Set(idList)].map((id: number) => {
          for (var v of res) {
            if (v.finalLocation.id == id) {
              tempOrgList.push(v.finalLocation);
              break;
            }
          }
        })
        setOrgList(tempOrgList);
        console.log(tempOrgList, [...new Set(idList)]);

      });
    if (check30minBefore) setCountHalfHourRequest(countHalfHourRequest + 1);
    else setCountHalfHourRequest(1);
  }

  //june folding

  const onToggleFold = (id: number) => {
    if (fold === id) {
      setFold(0)
    } else {
      var delayInMilliseconds = 500; //1 second
      userStore.setVisitTableLoading(true);
      setTimeout(function () {
        userStore.getChangeRequestListByVisitId(id);
      }, delayInMilliseconds);
      userStore.getChangeRequestListByVisitId(id);
      setFold(id)
      // onToggleChangeVisitList(id)
    }
  }

  return (
    <Layout pageName={t('DASHBOARD')}>
      <div className="grid grid-cols-3 gap-2 mb-4">
        {/* <div className="col-span-1">
          <Typography variant="h5" fontWeight={'bold'} marginBottom={2}
            sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
            {t('OVERVIEW')}
          </Typography>
          <div className="bg-white rounded-lg p-2 py-6 justify-center">
            <div
              style={{
                width: 250,
                height: 130,
                backgroundImage: `url(${panelImg3})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                position: 'relative'
              }}>
              <div className="absolute top-1/2 w-full text-center text-white" style={{ transform: 'translate(0, -50%)' }}>
                <Typography>{t('STUDENTS')}</Typography>
                <Typography variant="h5">{userStore.yearlyVisitData.currentYearData.visitStudentNumber}</Typography>
                <Typography variant="caption">{t('LAST_YEAR')}: {userStore.yearlyVisitData.lastYearData.visitStudentNumber}</Typography>
              </div>
            </div>
            <div
              style={{
                width: 250,
                height: 130,
                backgroundImage: `url(${panelImg})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                position: 'relative'
              }}>
              <div className="absolute top-1/2 w-full text-center text-white" style={{ transform: 'translate(0, -50%)' }}>
                <Typography>{t('TEACHERS')}</Typography>
                <Typography variant="h5">{userStore.yearlyVisitData.currentYearData.visitTeacherNumber}</Typography>
                <Typography variant="caption">{t('LAST_YEAR')}: {userStore.yearlyVisitData.lastYearData.visitTeacherNumber}</Typography>
              </div>
            </div>
            <div
              style={{
                width: 250,
                height: 130,
                backgroundImage: `url(${panelImg2})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                position: 'relative'
              }}>
              <div className="absolute top-1/2 w-full text-center text-white" style={{ transform: 'translate(0, -50%)' }}>
                <Typography>{t('ORGANISATIONS')}</Typography>
                <Typography variant="h5">{userStore.yearlyVisitData.currentYearData.visitOrgNumber}</Typography>
                <Typography variant="caption">{t('LAST_YEAR')}: {userStore.yearlyVisitData.lastYearData.visitOrgNumber}</Typography>
              </div>
            </div>
          </div>
        </div> */}
        <div>
          <Wrapper apiKey={googleMapApiKey}>
            {userStore.loadingVisit ? <Skeleton variant="rectangular" sx={{ width: '100%' }} height={500} />
              : <Map
                center={{ lat: -31.363, lng: 151.044 }}
                zoom={6}
                style={{ flexGrow: "1", height: "100%", minHeight: 400 }}
              >
                {orgList.length > 0 &&
                  orgList.map((marker: any, index: number) => (
                    <MapMarker
                      position={{ lat: marker.latitude, lng: marker.longitude }}
                      popOutlabel={marker}
                      onClick={() => {
                        // setClearWindow(true);
                        setSelectedOrg(marker.id);
                        // setVisitPage(1);
                      }}
                      // clearWindow={clearWindow}
                      enableInitialPopOut={index == 0}
                    />
                  ))}
              </Map>}
          </Wrapper>
        </div>
        <div className="col-span-2">
          <Typography variant="h5" fontWeight={'bold'} marginBottom={2}
            sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
            {t('MSG_LIST')}
          </Typography>
          <VmTable
            loading={userStore.loading && userStore.msgList.length == 0}
            page={page}
            paginationCount={userStore.msgList.totalCount == 0 ? 1 : userStore.msgList.totalCount / PAGE_SIZE}
            onChangePagination={(event, pageNumber) => {
              setPage(pageNumber);
              userStore.getMessageList(PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
            }}
            thead={["ID", 'Message', t('TYPE'), t('SENDER'), t('RECEIVER'), t('SENT_TIME'), t('ACTION')]}
            tbody={userStore.msgList.data.length > 0 &&
              userStore.msgList.data.map((msg, index) => (
                <>
                  <tr key={`msg_${index}`}>
                    <td className={`${TD_FIRST} ${!msg.isRead && 'text-red-500 font-bold'}`}>{index + 1}</td>
                    <td className={`${TD_NORMAL} ${!msg.isRead && 'text-red-500 font-bold'}`}>{msg.category}</td>
                    <td className={TD_NORMAL}>{msg.title}</td>
                    <td className={TD_NORMAL}>{msg.senderType}</td>
                    <td className={TD_NORMAL}>{msg.receiverType}</td>
                    <td className={TD_NORMAL}>{msg.createTime.split('T')[0] + " " + msg.createTime.split('T')[1]}</td>
                    <td className={TD_LAST}>
                      <Button
                        variant="outlined"
                        color={msg.isRead ? "primary" : "error"}
                        sx={{ width: 'fit-content', paddingX: 1 }}
                        onClick={() => handleShowModal(msg.detail, msg.id)}

                      >
                        {t('VIEW_DETAIL')} {!msg.isRead && `(${t('UNREAD')})`}
                      </Button>
                    </td>
                  </tr>
                  <Box sx={{ marginY: 1 }} />
                </>
              ))}
          />
        </div>
        {/* <div className="col-span-1">
          <Typography variant="h5" fontWeight={'bold'} marginBottom={2}
            sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
            {t('TODAY')}
          </Typography>

          <div className="bg-white rounded-lg px-2 py-5 flex justify-center">
            <div>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                fontSize: 20,
                justifyContent: 'center',
                color: '#fe9f9f'
              }}>
                <PeopleIcon fontSize="inherit" color="inherit" />
                <Typography variant="caption" textAlign={"center"} marginLeft={1} color="black">{t('TEACHER_VISITS')}</Typography>
              </div>
              <div className="rounded-full relative"
                style={{ height: 100, width: 100, backgroundColor: '#fecacc', margin: '10px auto' }}>
                <div className="rounded-full absolute top-1/2 left-1/2"
                  style={{ height: 70, width: 70, backgroundColor: '#fe9f9f', transform: 'translate(-50%, -50%)' }}>
                  <Typography color="white" variant="h5"
                    sx={{ position: 'absolute', top: '50%', width: '100%', textAlign: 'center', transform: 'translate(0, -50%)' }}>
                    {userStore.todayVisitData.visitTeacherNumber}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="mx-6">
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                fontSize: 20,
                justifyContent: 'center',
                color: '#9092e9'
              }}>
                <AccountBalanceIcon fontSize="inherit" color="inherit" />
                <Typography variant="caption" textAlign={"center"} marginLeft={1} color="black">{t('ORGANISATION_VISITS')}</Typography>
              </div>
              <div className="rounded-full relative"
                style={{ height: 100, width: 100, backgroundColor: '#bbbef3', margin: '10px auto' }}>
                <div className="rounded-full absolute top-1/2 left-1/2"
                  style={{ height: 70, width: 70, backgroundColor: '#9092e9', transform: 'translate(-50%, -50%)' }}>
                  <Typography color="white" variant="h5"
                    sx={{ position: 'absolute', top: '50%', width: '100%', textAlign: 'center', transform: 'translate(0, -50%)' }}>
                    {userStore.todayVisitData.visitOrgNumber}
                  </Typography>
                </div>
              </div>
            </div>
            <div>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                fontSize: 20,
                justifyContent: 'center',
                color: '#faa201'
              }}>
                <EmojiPeopleIcon fontSize="inherit" color="inherit" />
                <Typography variant="caption" textAlign={"center"} marginLeft={1} color="black">{t('STUDENTS')} Visits</Typography>
              </div>
              <div className="rounded-full relative"
                style={{ height: 100, width: 100, backgroundColor: '#ffcc74', margin: '10px auto' }}>
                <div className="rounded-full absolute top-1/2 left-1/2"
                  style={{ height: 70, width: 70, backgroundColor: '#faa201', transform: 'translate(-50%, -50%)' }}>
                  <Typography color="white" variant="h5"
                    sx={{ position: 'absolute', top: '50%', width: '100%', textAlign: 'center', transform: 'translate(0, -50%)' }}>
                    {userStore.todayVisitData.visitStudentNumber}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      {/* <div className="grid grid-cols-5 gap-4">
        <TextField
          value={startDate}
          label={t('START_DATE')}
          type="date"
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2, background: 'white' }}
          className="col-span-2"
          onChange={(value) => setStartDate(value.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={endDate}
          label={t('END_DATE')}
          type="date"
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2, background: 'white' }}
          className="col-span-2"
          onChange={(value) => setEndDate(value.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          variant="outlined"
          sx={{ marginBottom: 2 }}
          disabled={new Date(startDate) > new Date(endDate)}
          onClick={() => onToggleVisitList()}
        >
          {t('SEARCH')}
        </Button>
      </div> */}
      <div className="grid grid-cols-2 gap-2 mb-4">
        {/* <Box>
        <Wrapper apiKey={googleMapApiKey}>
            {groupStore.loading ? <Skeleton variant="rectangular" sx={{ width: '100%' }} height={500} />
              : <Map
                center={{ lat: -30.363, lng: 151.044 }}
                zoom={5}
                style={{ flexGrow: "1", height: "100%" }}
              >
                {groupStore.visitInDateRangeList.data.length > 0 &&
                  groupStore.visitInDateRangeList.data.map((marker: any, index: number) => (
                    <MapMarker
                      position={{ lat: marker.latitude, lng: marker.longitude }}
                      popOutlabel={marker}
                      onClick={() => {
                        // setClearWindow(true);
                        setSelectedOrg(marker.id);
                        setVisitPage(1);
                      }}
                      clearWindow={clearWindow}
                      enableInitialPopOut={index == 0}
                    />
                  ))}
              </Map>
            }
          </Wrapper>
        </Box> */}
        <Box className="col-span-2">
          {groupStore.loading ? <>
            <div className="text-center mb-4"><Skeleton sx={{ width: '50%' }} /></div>
            <div className="text-center my-4"><Skeleton /></div>
            <div className="text-center my-4"><Skeleton /></div>
            <div className="text-center my-4"><Skeleton /></div>
            <div className="text-center my-4"><Skeleton /></div>
            <div className="text-center my-4"><Skeleton /></div>
          </> : <>
            <Typography variant={"h6"}
              sx={{ borderLeftWidth: 6, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}
            >
              {/* Selected: {groupStore.visitInDateRangeList.data.length > 0 && selectedOrg != 0 &&
                groupStore.visitInDateRangeList.data.find(v => v.id == selectedOrg).name} */}
              Today Visit List (Since {currentTime})
            </Typography>
            {/* <Typography variant="subtitle2" color="red">
              Tip: Click the marker inside the map at left to switch the visit list based on organisation
            </Typography> */}

            <Box sx={ITEM_PERFECT_INLINED} className="justify-between">
              <div>
                <Box sx={ITEM_PERFECT_INLINED} className="mb-2">
                  <span className="bg-sky-400 text-sky-400 rounded-2xl text-white p-2 pt-1 mr-2" />
                  <Typography>Trainer & Assesor Not Check In Yet</Typography>
                  <span className="bg-orange-400 text-orange-400 rounded-2xl text-white p-2 pt-1 ml-4 mr-2" />
                  <Typography> Check In By Trainer & Assesor</Typography>
                  <span className="bg-green-400 text-green-400 rounded-2xl text-white p-2 pt-1 ml-4 mr-2" />
                  <Typography> Check Out By Trainer & Assesor</Typography>
                </Box>
              </div>
              <div>
                <IconButton title="Go 30mins earlier" disabled={userStore.loadingVisit && userStore.todayFullList.length != 0} onClick={() => onRequestTodayVisitListByTime(true)} className="text-theme">
                  {userStore.loadingVisit && userStore.todayFullList.length != 0 ?
                    <Typography fontWeight={"bold"} variant="body1">Fetching Visit List...</Typography>
                    : <ArrowCircleUpIcon fontSize={"large"} color={'inherit'} />}
                </IconButton>
              </div>
            </Box>

            {/* from here june */}
            <div className="">
              <VmTable
                loading={userStore.loadingVisit && userStore.todayFullList.length == 0}
                page={0}
                enableScroll={true}
                // paginationCount={(groupStore.visitInDateRangeList.data.length == 0 || selectedOrg == 0 ? 1
                //   : (groupStore.visitInDateRangeList.data.find(o => o.id == selectedOrg).organisationVisits.length / PAGE_SIZE))}
                // onChangePagination={(event, pageNumber) => {
                //   setVisitPage(pageNumber);
                // }}
                thead={["ID", t('VISIT_NAME'), "Date", 'Scheduled Start At', 'Scheduled End At', "Check In At", "Check Out At", "Check in " + t('STATUS'), t('ACTION')]}
                tbody={userStore.todayFullList.map((visit, index) => (
                  <>
                    <Box marginY={1} />
                    <tr key={`visit_${index}`}>
                      <td className={TD_FIRST}>{index + 1}</td>
                      <td className={TD_NORMAL}>{visit.name}</td>
                      <td className={TD_NORMAL}>{DateUtils.onConvertDateFormat(visit.finalStartTime.split('T')[0])}</td>
                      <td className={TD_NORMAL}>{visit.finalStartTime.split('T')[1]}</td>
                      <td className={TD_NORMAL}>{visit.finalEndTime.split('T')[1]}</td>
                      <td className={TD_NORMAL}>{visit.checkInTime ? visit.checkInTime.split('T')[1] : "-"}</td>
                      <td className={TD_NORMAL}>{visit.checkOutTime ? visit.checkOutTime.split('T')[1] : "-"}</td>
                      <td className={TD_NORMAL}>
                        <span className={`${visit.checkOutTime ? "bg-green-400" : visit.checkInTime ? "bg-orange-400" : "bg-sky-400"} rounded-2xl text-white p-2 pt-1`}>
                          <Typography variant="caption">{visit.checkOutTime ? "Checked Out" : visit.checkInTime ? t('CHECKED_IN_BY_TEACHER') : t('NOT_CHECK_IN_BY_TEACHER')}</Typography>
                        </span>
                      </td>
                      <td className={TD_LAST}>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setShowVisitModal(true);
                            setSelectedVisit(visit);
                          }}
                        >
                          {t('VIEW')}
                        </Button>
                      </td>
                    </tr>


                    <Box marginY={1} />
                  </>
                ))}
              />
            </div>

          </>}
        </Box>
      </div>

      <VmModal
        width={400}
        openModal={showVisitModal && selectedVisit}
        onClose={() => {
          setShowVisitModal(false);
          setSelectedVisit(null);
        }}
        title={showVisitModal && selectedVisit.name}
        showButton={false}
      >
        {showVisitModal &&
          <>
            <table style={{ marginTop: -20, width: '100%' }}>
              <thead><th /><th /></thead>
              <tbody>
                <tr style={divider}>
                  <td className="font-bold">{t('Location')}: </td>
                  <td className="text-right">{selectedVisit.finalLocation && selectedVisit.finalLocation.name}</td>
                </tr>
                <tr style={divider}>
                  <td className="font-bold">{t('STUDENT_SINGLE')}: </td>
                  <td className="text-right">{selectedVisit.studentName ? selectedVisit.studentName : "No Data"}</td>
                </tr>
                <tr style={divider}>
                  <td className="font-bold">{t('CREATE_TIME')}: </td>
                  <td className="text-right">{selectedVisit.createTime.split('T')[0] + " " + selectedVisit.createTime.split('T')[1]}</td>
                </tr>
                <tr style={divider}>
                  <td className="font-bold">{t('UPDATED_TIME')}: </td>
                  <td className="text-right">{selectedVisit.updateTime.split('T')[0] + " " + selectedVisit.updateTime.split('T')[1]}</td>
                </tr>
                <tr style={divider}>
                  <td className="font-bold">{t('START_TIME')}: </td>
                  <td className="text-right">{selectedVisit.finalStartTime.split('T')[0] + " " + selectedVisit.finalStartTime.split('T')[1]}</td>
                </tr>
                <tr style={divider}>
                  <td className="font-bold">{t('END_TIME')}: </td>
                  <td className="text-right">{selectedVisit.finalEndTime.split('T')[0] + " " + selectedVisit.finalEndTime.split('T')[1]}</td>
                </tr>
                <tr style={divider}>
                  <td className="font-bold">Responsible Trainer Name: </td>
                  <td className="text-right">{selectedVisit.responsibleTrainerName}</td>
                </tr>

                <tr style={divider}>
                  <td className="font-bold">Conduct Trainer Name: </td>
                  <td className="text-right">{selectedVisit.conductTrainerName}</td>
                </tr>

                <tr style={divider}>
                  <td className="font-bold">{t('DESCRIPTION')}: </td>
                </tr>

                <tr>
                  <td colSpan={2}>
                    <TextField
                      value={selectedVisit.description}
                      sx={{ width: '100%' }}
                      multiline
                      rows={2}
                      disabled
                      size="small"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </>}
      </VmModal>

      <VmModal
        openModal={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedMsg(""); location.pathname.includes("/group")
        }}
        width={1000}
        title={t('MSG_DETAIL')}
        showButton={false}
      >
        {selectedMsg !== "" &&
          // @ts-ignore
          <div className="overflow-auto" style={{ maxHeight: 500 }} dangerouslySetInnerHTML={{ __html: selectedMsg }} style={{ marginTop: -10 }} />}
      </VmModal>
    </Layout >
  );
});

const divider = {
  borderBottom: "1px solid #D3D3D3",
};


export default Dashboard;
